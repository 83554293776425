import * as React from "react";
import { css } from "@emotion/css";
import clsx from "clsx";

import {
  Block,
  Button,
  Contact,
  Page,
  Section,
  SubSection,
} from "../components";

import backgroundimg from "../images/backgroundimg.jpg";
import img01 from "../images/startup.png";
import img02 from "../images/line-chart.png";
import img03 from "../images/devices.png";
import img04 from "../images/chu-rouen-5.jpg";
import img05 from "../images/avicenne-1.jpg";
import img06 from "../images/marque_antadir_q.jpg";
import img07 from "../images/cak-1.png";
import img08 from "../images/logo_adir-2.png";
import app01 from "../images/app01.jpg";
import app02 from "../images/app02.jpg";
import app03 from "../images/app03.jpg";
import app04 from "../images/app04.jpg";
import app05 from "../images/app05.jpg";
import app06 from "../images/app06.jpg";
import Waves from "../assets/waveshape.svg";

import {
  h1Style,
  contentStyle,
  h2Style_w,
  h4Style,
  GreyBlock,
  threeColBlock,
  p2Style,
  waveBlock,
  featureItem,
} from "../globalstyle";

const LandingOne = () => {
  return (
    <Page tabTitle="Présentation">
      <Block className={GreyBlock}>
        <div className={contentStyle}>
          <h1 className={h1Style}>
            Recueillez et analysez vos données de recherche clinique en toute
            simplicité
          </h1>
          <p className={p2Style}>
            Sur <b>Dotter.science</b>, créez facilement des formulaires de
            recueil et optimisez la saisie de vos bases de données de recherche
            clinique, afin d’en faciliter l’analyse statistique et la
            publication. Destiné aux chercheurs, professionnels et étudiants en
            santé, <b>Dotter.science</b> permet de gagner en temps et en
            efficacité.
          </p>
          <Button link="#get-demo" color="red">
            DÉCOUVRIR GRATUITEMENT
          </Button>
        </div>
      </Block>
      <Waves
        className={css`
          width: 100vw;
          height: auto;
          margin: 0;
        `}
      />
      <div className={waveBlock}>
        <img
          src={backgroundimg}
          alt="wave"
          className={css`
            position: fixed;
            z-index: -1;
            top: 0;
            height: 100vh;
            width: auto;
            @media screen and (max-width: 600px) {
              align-self: center;
              height: 100vh;
              width: auto;
            }
          `}
        />
      </div>
      <Section
        title="Les fonctionnalités Dotter.science"
        color="turquoise"
        pSize={4}
        callToAction={
          <Button link="/fonctionnalites" color="white">
            EN SAVOIR PLUS SUR LES FONCTIONNALITÉS
          </Button>
        }
        column
      >
        <div className={featureItem}>
          <img src={img01} alt="Collecte des données optimisée" />
          <div>
            <h5 className={h2Style_w}>Collecte des données optimisée</h5>
            <p className="content">
              Concevez vos cahiers de recueil électroniques (eCRF) sur notre
              interface graphique conviviale, et commencez rapidement à inclure
              vos patients grâce à nos outils de vérification en temps réel de
              la cohérence des données.
            </p>
          </div>
        </div>
        <div className={featureItem}>
          <img src={img02} alt="Analyse statistique simplifiée" />
          <div>
            <h5 className={h2Style_w}>Analyse statistique simplifiée</h5>
            <p className="content">
              Avec <b>Dotter.science</b>, plus besoin de papier ! Vos données
              sont sécurisées sur des serveurs certifiés pendant toute la durée
              de l’étude, et exportables facilement dans des formats structurés
              prêts à être analysés. Sur demande, nous vous aidons à réaliser
              vos statistiques.
            </p>
          </div>
        </div>
        <div className={featureItem}>
          <img src={img03} alt="Multi-plateforme et collaboratif" />
          <div>
            <h5 className={h2Style_w}>Multi-plateforme et collaboratif</h5>
            <p className="content">
              Une fois votre compte personnel créé depuis tout ordinateur,
              tablette ou smartphone, réalisez vos projets et collaborez
              simplement avec les membres de votre équipe, pour faire avancer la
              recherche toujours plus vite.
            </p>
          </div>
        </div>
      </Section>
      <Section title="Nos engagements" color="white" pSize={2} column>
        <div
          className={clsx(
            threeColBlock,
            css`
              width: 60vw;
              @media screen and (max-width: 600px) {
                width: 90vw;
              } ;
            `
          )}
        >
          <div
            className={css`
              text-align: left;
            `}
          >
            <h4 className={h4Style}>Un soutien</h4>
            <p className="content">
              À toutes les phases de l’étude, de la conception jusqu’à l’analyse
              des données.
            </p>
          </div>
          <div
            className={css`
              text-align: left;
            `}
          >
            <h4 className={h4Style}>La fiabilité</h4>
            <p className="content">
              Du recueil, pour des études de qualité valorisables par la
              publication.
            </p>
          </div>
          <div
            className={css`
              text-align: left;
            `}
          >
            <h4 className={h4Style}>Du temps</h4>
            <p className="content">
              Pour soi et pour sa pratique clinique, grâce à la diminution des
              tâches redondantes.
            </p>
          </div>
        </div>

        <div
          className={css`
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          `}
        >
          <img src={app01} alt="Spécialités médicales" />
          <img src={app02} alt="Biologie et pharmacologie" />
          <img src={app03} alt="Spécialités paramédicales" />
          <img src={app04} alt="Prothèses et implants" />
          <img src={app05} alt="Épidémiologie et santé publique" />
          <img src={app06} alt="Spécialités chirurgicales" />
        </div>
      </Section>
      <div id="get-demo"></div>
      <Section
        color="green"
        pSize={4}
        callToAction={
          <>
            <Button link="https://demo.dotter.science/" color="white">
              OBTENIR MON ACCÈS GRATUIT À UNE ÉTUDE DE DÉMONSTRATION
            </Button>
            <Button link="/faq" color="white">
              CONSULTER LA FOIRE AUX QUESTIONS
            </Button>
          </>
        }
      >
        <SubSection title="La preuve par l’exemple !">
          <p className="content">
            Demandez dès aujourd’hui votre accès à une étude de démonstration.
            Vous verrez comment appréhender rapidement la collecte et la gestion
            des données sur une étude de test.
          </p>
          <p className="content">
            Pour toute autre question, n’hésitez pas à nous contacter ou à
            consulter notre FAQ !
          </p>
        </SubSection>
      </Section>
      <Section
        color="white"
        title="Ils utilisent Dotter.science pour leurs projets"
      >
        <div
          className={css`
            display: flex;
            width: 80vw;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
            margin: 10vh;
            & img {
              padding: 20px;
            }
          `}
        >
          <img src={img04} width="176" />
          <img src={img05} width="176" />
          <img src={img06} width="176" />
          <img src={img07} width="176" />
          <img src={img08} width="176" />
        </div>
      </Section>

      <Contact />
    </Page>
  );
};

export default LandingOne;
